<template>
    <div class="">
      <!-- <link href="https://vjs.zencdn.net/7.19.2/video-js.css" rel="stylesheet" /> -->
      <video
        ref="videoPlayer"
        class="video-js w-full h-full"
        :controls="true"
        preload="auto"
        width="600"
        height="300"
        @touchend="handleTouchEnd"
        @click="handleTouchEnd"
      >
        <source :src="src" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

    </div>
  </template>

  <script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css';
  import { router } from '@inertiajs/vue3';

  export default {
    props: {
      src: {
        type: String,
        required: true,
      },
      media:{
        type: Object,
        required: true,
      }
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer);
        this.player.ready(() => {
            this.player.play().catch((error) => {
                console.error("Error attempting to play:", error);
            });
            // this.player.controlBar.hide(); // Hide the control bar

            // // Create a custom play button
            // this.addCustomPlayButton();
        });
        this.boundHandleTechTap_ = (e) => this.handleTechTap_(e);

        // Add event listener for the 'tap' event (or 'click' event for simplicity)
        this.player.on('tap', this.boundHandleTechTap_);


    },
    beforeDestroy() {
      if (this.player) {
            // this.player.off('tap', this.boundHandleTechTap_);
        this.player.dispose();
      }
    },
    methods: {
        addCustomPlayButton() {
            // Add custom play button logic
            const playButton = document.createElement('button');
            playButton.classList.add('vjs-play-button');
            playButton.innerHTML = '►';
            playButton.style.position = 'absolute';
            playButton.style.top = '50%';
            playButton.style.left = '50%';
            playButton.style.transform = 'translate(-50%, -50%)';
            playButton.style.fontSize = '30px';
            playButton.style.padding = '10px 20px';
            playButton.style.border = 'none';
            playButton.style.backgroundColor = 'rgba(43, 51, 63, 0.7)';
            playButton.style.color = 'white';
            playButton.style.borderRadius = '5px';
            playButton.style.cursor = 'pointer';
            playButton.addEventListener('click', () => {
                this.handleTap();
            });
            this.$refs.videoPlayer.parentNode.appendChild(playButton);
        },
        playVideo() {
            this.player.play();
        },
        pauseVideo() {
            this.player.pause();
        },
        handleTap() {
            console.log("Video tapped", this.media?.id);
            this.playVideo();
            // router.get(route('reels',{ id: this.media?.id}));
        },
        handleTouchEnd(event) {
            this.pauseVideo();
        },
    },
  };
  </script>

  <style scoped>
  .video-js .vjs-big-play-button {
    font-size: 3em;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 1;
    border: 2px solid #fff;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 50%;
    transition: background-color 0.4s;
  }

  .video-js .vjs-big-play-button:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }

  .video-js .vjs-big-play-button:before {
    font-size: 30px;
    color: white;
  }
  </style>
